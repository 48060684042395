import React from 'react';
import AppWrapper from 'styles/keys.css';
import withContext from 'store/withContext';

var apiEnv = process.env.NODE_ENV === 'development' ? 'https://localhost:3006' : 'https://api.hirezstudios.com';

class Keys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jwt: undefined,
      loading: false,
      status: undefined
    }
  }

  updateRoute = (route) => {
    this.props.context.actions.updateRoute(route);
  }

  readCookie = (name) => {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  clearCache = () => {
    this.setState({loading: true});
    fetch(`${apiEnv}/acct/clearWpCache`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${this.state.jwt ? this.state.jwt : ""}`
      },
      body: `{
        "jwt": "${this.state.jwt}"
      }`
    })
    .then(res => res.json())
    .then(res => {
      if (res.hasOwnProperty('status') && res.status === "success") {
        this.setState({
          loading: false,
          status: "Success!"
        });
      }
      else this.setState({
        loading: false,
        status: 'There was an error.'
      });
    });
  }

  componentDidMount() {
    this.updateRoute(null);
    let jwtCookie = this.readCookie("jwt");
    if (jwtCookie) {
      this.setState({jwt: jwtCookie})
    }
  }

  render() {
    return (
      <AppWrapper className="keys wp-cache">
        {(this.props.context.availableTools && (this.props.context.availableTools.includes('wp-cache'))) && 
        <div>
          <p>{this.state.status && this.state.status}</p>
          {!this.state.loading &&
          <button className="button" onClick={this.clearCache}>Clear Wordpress Cache</button>
          }
          {this.state.loading &&
          <p>The cache is being cleared...</p>
          }
        </div>
        }
      </AppWrapper>
    );
  }
}

export default withContext(Keys);
