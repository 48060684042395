import styled from 'styled-components';


const AppWrapper = styled.div`
overflow-x: hidden;
padding: 0 2rem;
.button-container {
  text-align: center;
}
.button {
  background-color: #142743;
  border-radius: 5px;
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  margin: 2rem auto 0;
  padding: 1rem 2rem;
  &.home {
    font-size: 1.4rem;
    padding: 1rem;
  }
  &.home, &.create {
    margin-bottom: 2rem;
  }
}
h3 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 2rem;
}
input, select {
  margin: 1rem 0;
}
input {
  margin-left: 1rem;
}
.keys-create, .keys-edit {
  h3 {
    color: green;
    &.error {
      color: red
    }
  }
}
.keys-edit {
  .key-status, .link {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    padding: 0.5rem 0;
  }
}
.route.home {
  .campaigns {
    display: inline-flex;
    flex-direction: column;
  }
  .campaign {
    align-items: center;
    display: grid;
    grid-template: 'a b c d';
    padding: 0.5rem;
    min-width: 5rem;
    &:nth-of-type(odd) {
      background: #eaeaea;
    }
    .buttons {
      text-align: center;
    }
    button {
      margin: 0 0.5rem;
    }
    p {
      font-family: Arial, Helvetica, sans-serif;
      margin-right: 1rem;
    }
    .status {
      font-size: 1.2rem;
      margin-left: 1rem;
      text-transform: capitalize;
    }
    .link {
      cursor: pointer;
      font-size: 1.2rem;
      padding: 0 0.5rem;
      text-align: center;
    }
  }
}
.hidden-link {
  position: absolute;
  right: 100000rem;
}

.response {
  margin-top: 2rem;
}

`;

export default AppWrapper;